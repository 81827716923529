@import "./variables/colors.scss";
@import "./variables/spacing.scss";
@import "./variables/screen-sizes.scss";
@import "./mixins/screen.scss";
@import "./utilities";
@import "./overrides";
@import "~antd/dist/antd.css";

*,
*::before,
*::after {
  box-sizing: border-box !important;
}

html,
body {
  font-family: $base-font-family;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.max-page-width {
  max-width: $screen-xxl;
  margin-left: auto;
  margin-right: auto;
}

.default-wrapper-padding {
  padding-left: $spacing-5;
  padding-right: $spacing-5;
  @media (max-width: $screen-sm) {
    padding-left: $spacing-4;
    padding-right: $spacing-4;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}